import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='My Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        I made the serious commitment of providing the best-in-class support for my projects. If we collaborated in the
        past or if you purchased one of my apps, feel free
        to contact me for support! As long as it does not interfere with my current job, I will be there to help you.
      </Text>
    </Card>
  </Section>
)

export default Commitment
