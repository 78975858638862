import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact me' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='Whether you have a business proposal, you need some help or just want to chat,
          I am always keen on answering!'
          running='Feel free to send me a message about anything, really.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <ContactInfo />
        <Divider />
        <Commitment />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
